import { BBAI } from './types'

export const bbai: BBAI = {
	marketing: {
		benefits: [
			{
				description: 'Go from idea to initial design in minutes',
				icon: 'fast-forward',
				title: 'Fast',
			},
			{
				description: 'Helpful AI suggestions along the way',
				icon: 'wand-2',
				title: 'Easy',
			},
			{
				description: 'Share your design with others to get feedback',
				icon: 'users',
				title: 'Together',
			},
		],
		description: {
			meta: 'BrainBuild is a platform that enables you to quickly generate product specs and wireframes using AI, helping you move from an idea to a prototype design in minutes. Tools for businesses, designers, and developers with flexible pricing plans and features for your entire workflow.',
			short: 'BrainBuildAI helps you design apps your users will love',
		},
		features: [
			{
				description: 'Understand your users better to build the right product',
				icon: 'component',
				title: 'Personas and Pain Points',
			},
			{
				description: 'Add features that solve real problems',
				icon: 'box',
				title: 'Features',
			},

			{
				description: 'Design how users move through your app',
				icon: 'workflow',
				title: 'User Flows',
			},
			{
				description: 'Define the data structure of your database',
				icon: 'box',
				title: 'Data Model',
			},
			{
				description: 'Be ready to pitch your idea and market it online',
				icon: 'presentation',
				title: 'Slides and PDF',
			},
			{
				description: 'Wireframe editor to design your app screens',
				icon: 'file-text',
				title: 'Pages',
			},
		],
		hero: {
			main: 'Generate an app design in a few minutes with AI',
			sub: 'Want to design an app?',
		},
		name: 'BrainBuildAI',
		personas: [
			{
				description: 'Define your product so you can get to market faster',
				icon: 'briefcase-business',
				title: 'Business',
			},
			{
				description:
					"Have a starting point so you don't have to start from scratch",
				icon: 'brush',
				title: 'Designers',
			},
			{
				description: 'Define your product so you can get to market faster',
				icon: 'code',
				title: 'Developers',
			},
		],
	},
	websiteDomain: 'www.brainbuildai.com',
}
